<template>
  <div class="search-wrap background-color-4">
    <div class="padding-top15">
      <form action="/">
        <van-search
          v-model="value"
          :placeholder="$t('coinList.placeholder')"
          :background="'transparent'"
          show-action
          shape="round"
          @input="onSearch"
        >
          <div slot="action" class="text-c active-color-1" @click="onCancel">{{$t('cancel')}}</div>
        </van-search>
      </form>
    </div>
    <div v-if="!value" class="no-search-box padding-left15 padding-top15 padding-right15">
      <div class="ub ub-btw">
        <p class="font-weight500 font16"> {{ $t('coinList.searchHistory') }} </p>
        <van-icon size="20" name="delete" @click="onDeleteHistory"/>
      </div>
      <div class="search-history-wrap padding-top15 ub-wrap ub font12">
        <div class="history-item padding8 background-color-1 margin-left8 margin-top8 main-text-1" v-for="item in searchHistory" @click="historySearchFn(item)" :key="item">
          {{ item }}
        </div>
      </div>
    </div>
    <div v-if="value" class="search-box content-box padding-left15 padding-top15 padding-right15">
      <ul>
        <li class="margin-bottom8 padding8" v-for="(item, index) in symbolInfoList" @click="onDetail(item)" :key="index">
          <van-row align="center"  type="flex">
            <van-col span="12"  class="ub" >
              <div class="ub ub-h-c">
                <p class=" font16 font-weight500">{{item.symbol}}</p>
                <p class="feature font12 font-weight400 margin-left8">{{ $t('market.perprtual') }}</p>
              </div>
            </van-col>
            <van-col span="12"  class="ub ub-tope" >
              <div>
                <p class="text-r font14 font-weight500"> {{ item.last_price }}</p>
                <p class="text-r font12" :style="{ color: `var(${item.color})` }">{{item.price_change_percent}}%</p>
              </div>
            </van-col>
          </van-row>
        </li>
      </ul>
      <NoData v-if="loadStatus === 'requested' && !symbolInfoList.length" :tip="$t('coinList.noFound')"></NoData>
    </div>
  </div>
</template>
<script>
import { symbolSearch, ticker24hList } from '@/api/market'
import NoData from '@/components/market/noData'
import { Row, Col, Toast } from 'vant'
import Vue from 'vue'
Vue.use(Row).use(Col)
Vue.use(Toast)
export default {
  components: {
    NoData
  },
  data () {
    return {
      value: '',
      searchHistory: [],
      symbolList: [],
      symbolInfoList: [],
      searchTime: null,
      searchToast: null,
      // pending 等待请求 requested 请求完成
      loadStatus: 'pending'
    }
  },
  created () {
    this.searchHistory.length = 0;
    (JSON.parse(localStorage.getItem('searchHistory')) || []).forEach(symbol => {
      this.searchHistory.push(symbol)
    })
  },
  beforeDestroy () {
    clearTimeout(this.searchTime)
    this.searchTime = null
  },
  methods: {
    historySearchFn (symbol) {
      this.value = symbol
      this.onSearch()
    },
    changeColor (priceChangePercent) {
      let className
      if (priceChangePercent > 0) {
        className = '--up-color'
      }
      if (priceChangePercent < 0) {
        className = '--down-color'
      }
      if (priceChangePercent === 0) {
        className = '--common-color'
      }
      return className
    },
    ticker24hListFn (symbolArr) {
      ticker24hList({
        symbol_list: symbolArr
      }).then((res) => {
        if (this.searchToast) {
          this.searchToast.clear()
          this.searchToast = null
        }
        this.loadStatus = 'requested'
        console.log(res, 'ticker24hListFn')
        this.symbolInfoList = res.map((item, index) => {
          return {
            color: this.changeColor(item.price_change_percent),
            symbol: this.symbolList[index].symbol,
            ...item
          }
        })
      })
    },
    setSearchHistory () {
      localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))
    },
    onDeleteHistory () {
      console.log(this.searchHistory)
      localStorage.removeItem('searchHistory')
      this.searchHistory.length = 0
      this.$forceUpdate()
    },
    onCancel () {
      this.$router.back()
    },
    onSearch () {
      if (this.searchTime) {
        clearTimeout(this.searchTime)
        this.searchTime = null
      }
      this.symbolList = []
      this.symbolInfoList = []
      if (!this.value) {
        return false
      }
      this.loadStatus = 'pending'
      this.searchTime = setTimeout(() => {
        console.log(this.value, 'value=======search')
        this.searchToast = this.$toast.loading({
          mask: true,
          message: ' '
        })
        this.symbolSearchFn()
      }, 500)
    },
    symbolSearchFn () {
      symbolSearch({
        symbol: this.value
      }).then(res => {
        this.symbolList = res
        const symbolArr = res.map(item => {
          return item.symbol
        })
        this.ticker24hListFn(symbolArr)
        console.log(res, 'symbolSearchFn-----------')
      })
    },
    onDetail (item) {
      const symbol = item.symbol
      if (this.searchHistory.indexOf(symbol) === -1) {
        if (this.searchHistory.length < 6) {
          this.searchHistory.unshift(symbol)
        } else {
          this.searchHistory.pop()
          this.searchHistory.unshift(symbol)
        }
        this.setSearchHistory()
      }
      this.$router.push({
        path: `/trade/spot-kline/${symbol}`
      })
    }
  }
}
</script>
<style lang="less">
.search-wrap{
  width: 100vw;
  height: 100vh;
}
.content-box{
  height: calc(100vh - 200px);
  overflow: scroll;
}
</style>
