<template>
  <div class="no-data-wrap ub ub-cen">
    <div class="content-wrap">
      <img src="@/assets/images/common/nodata.svg" alt="" srcset="">
      <p class="text-c">{{ tip }}</p>
      <p v-if="refreshBtn" @click="refresh" class="text-c margin-top30 active-color-1">{{ $t('refresh') }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tip: {
      type: String,
      default: 'No Favorites'
    },
    refreshBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    refresh () {
      console.log('refreshFn')
      this.$emit('refresh')
    }
  }
}
</script>
<style lang="less">
.no-data-wrap{
  width: 100%;
  height: 75%;
  background-color: rgba(0, 0, 0, 0);
  color: var(--main-text-3);
  text-align: center;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  .content-wrap{
    display: inline-block;
  }
}
</style>
