import { requestPost, requestGet } from '@/utils/axios.js'
const path = '/futures-market-center/v1'
export const symbolPage = (params) => {
  const config = {
    loading: false
  }
  return requestGet(`${path}/symbol/page`, params, config)
}
export const ticker24hList = (params) => {
  const config = {
    loading: false
  }
  return requestPost(`${path}/ticker-24h/list`, params, config)
}
export const symbolSearch = (symbol) => {
  return requestGet(`${path}/symbol/search`, symbol)
}
export const optionalList = (params) => {
  const config = {
    loading: false
  }
  return requestPost('/passport/v1/user/symbol/optional/list', params, config)
}
export const Ticker = (symbol) => {
  return requestGet(`${path}/ticker-24h/get`, { symbol })
}
// k线 获取大于上一个开始时间的kline
export const KlineLaster = (params) => {
  return requestGet(`${path}/kline/latest`, params)
}
export const KlineNews = (params) => {
  return requestGet(`${path}/kline/latest-500`, params)
}
// 交易
export const TradeLaster = (params) => {
  return requestGet(`${path}/agg-trade/latest`, params)
}
export const TradeNews = (params) => {
  return requestGet(`${path}/agg-trade/latest-100`, params)
}
// 深度
export const Deep = (params) => {
  return requestGet(`${path}/depth/get`, params)
}
// 标记价格
export const MarkPriceAPi = params => {
  return requestGet(`${path}/mark-price/get`, params)
}
// 标记价格
export const MarkPriceListAPi = params => {
  const config = {
    loading: false
  }
  return requestPost(`${path}/mark-price/list`, params, config)
}
