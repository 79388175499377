<template>
  <div>
    <SearchCpt></SearchCpt>
  </div>
</template>
<script>
import SearchCpt from '@/components/market/search/index.vue'

export default {
  name: 'Search',
  components: { SearchCpt },
  computed: {
  },
  methods: {
  }
}
</script>
